<template>
  <div class="v-stack h-stretch gap-3" v-if="project">
    <div class="panel-right h-stretch">
      <div>Crew or Logs or Tasks</div>
    </div>

    <div class="pane-custom">
      <a class="heading-title-2">Client:</a>
      <div class="text-left">{{ project.client.name }}</div>
    </div>
    <div class="pane-custom">
      <a class="heading-title-2">Title:</a>
      <div class="text-left">{{ project.title }}</div>
    </div>
    <div class="pane-custom">
      <a class="heading-title-2">Subtitle:</a>
      <div class="text-left">{{ project.subtitle || "Empty" }}</div>
    </div>
    <div class="pane-custom">
      <a class="heading-title-2">Created:</a>
      <div class="text-left">
        {{ formatDate(project.createdDate, "DD.MM.YYYY") }}
      </div>
    </div>
    <div class="pane-custom">
      <a class="heading-title-2">Deadline:</a>
      <div class="text-left">
        {{
          project.deadline
            ? formatDate(project.deadline, "DD.MM.YYYY")
            : "Not set"
        }}
      </div>
    </div>
    <div class="pane-custom">
      <a class="heading-title-2">Last Edit:</a>
      <div class="text-left">
        {{ formatDate(project.lastEdited, "HH:mm DD.MM.YYYY") }}
      </div>
    </div>
    <div class="pane-custom">
      <a class="heading-title-2">Links:</a>
      <div class="text-left" v-if="project.links.length == 0">None</div>
      <div class="h-stack gap-3 h-start">
        <a
          v-for="link in project.links"
          :key="link._id"
          class="text-left"
          href="#"
          @click="openLink(link.url)"
          >{{ link.name }}</a
        >
      </div>
    </div>
    <div class="pane-custom">
      <a class="heading-title-2">Size:</a>
      <div class="description text-left">
        {{ format.currency(project.size) }} GB
      </div>
    </div>
    <div class="pane-custom">
      <a class="heading-title-2">Description:</a>
      <div
        class="description text-left"
        v-html="project.description || 'Empty'"
      ></div>
    </div>
    <a class="heading-title-2">Contact</a>
    <div class="card light mn">
      <div class="content">
        <div class="v-stack gap-3">
          <div class="pane-horizontal gap-3">
            <label class="text-right">Name:</label>
            <div class="text-left">{{ project.contactName || "Not Set" }}</div>
          </div>
          <div class="pane-horizontal gap-3">
            <label class="text-right">Phone:</label>
            <div class="text-left">{{ project.contactPhone || "Not Set" }}</div>
          </div>
          <div class="pane-horizontal gap-3">
            <label class="text-right">Email:</label>
            <div class="text-left">{{ project.contactEmail || "Not Set" }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="h-stack h-end">
      <button @click="$router.push('/projects/edit/' + id)" class="edit">
        Edit
      </button>
    </div>
    <a class="heading-title-2">Storage</a>
    <div class="table">
      <div class="table-header folder-row">
        <div class="text-left">Type</div>
        <div class="text-left">URL</div>
        <div class="text-left">Description</div>
      </div>
      <div
        v-for="folder in project.folders"
        :key="folder._id"
        class="table-row folder-row"
        @click="$router.push('/projects/folders/' + folder._id)"
      >
        <div class="text-left">{{ folder.type }}</div>
        <div class="text-left">
          {{ folder.harddrive.name }}/{{ project.client.name }}/{{
            project.title
          }}
          {{ project.subtitle }}
        </div>
        <div class="text-left">{{ folder.description }}</div>
      </div>
      <div v-if="project.folders.length == 0" class="table-row">
        <div>No Folders</div>
      </div>
    </div>
    <div class="h-stack h-end">
      <button
        class="add"
        @click="$router.push('/projects/folders/add/' + project._id)"
      >
        Add
      </button>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import utils from "@/utils.js";

export default {
  data() {
    return {
      format: utils.format,
    };
  },
  props: {
    id: {
      type: String,
      default: null,
    },
    project: {
      type: Object,
      default: null,
    },
  },
  methods: {
    openLink(link) {
      let url = link;

      if (!url) {
        return;
      }

      if (!url.startsWith("http")) {
        url = "https://" + url;
      }

      window.open(url, "_blank");
    },
    formatDate(dateString, format) {
      const date = moment(dateString);
      return date.format(format);
    },
  },
};
</script>

<style lang="scss" scoped>
.pane-custom {
  display: grid;
  align-content: stretch;
  justify-content: stretch;
  grid-auto-flow: column;
  grid-template-columns: 120px 1fr;

  .heading-title-2 {
    padding: unset;
  }
}

.description {
  white-space: pre-wrap;
}

.folder-row {
  grid-auto-columns: 100px 1fr 1fr !important;
  gap: 10px;
}
</style>
